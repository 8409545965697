import React from 'react';
import {createRoot}  from 'react-dom/client';
// import 'bootstrap/dist/css/bootstrap.min.css';
import App from './app';
import { BrowserRouter } from 'react-router-dom';

const root = createRoot(document.getElementById('app'));
root.render(
<BrowserRouter>
    <App />
  </BrowserRouter>);
import React, { Suspense, lazy } from 'react';
import { Route, Routes, useLocation  } from 'react-router-dom';

import ReactPixel from 'react-facebook-pixel';

import { ToastContainer } from 'react-toastify';
// import { Partytown } from '@builder.io/partytown/react';
import 'react-toastify/dist/ReactToastify.css';

// const ReactPixel = 'react-facebook-pixel';
const DetailedDashboard = lazy(()=> import('./admin/detailed-dashboard'));
const Dashboard = lazy(()=> import('./admin/dashboard'));
const Targets = lazy(()=> import('./admin/targets'));
const CreateCourse = lazy(()=> import('./admin/course/create'));
const CourseList = lazy(()=> import('./admin/course/list'));
const BlockchainList = lazy(()=> import('./admin/blockchains/list'));
const CreateCategory = lazy(()=> import('./admin/category/create'));
const EditCategory = lazy(()=> import('./admin/category/edit'));
const CreateBlockchain = lazy(()=> import('./admin/blockchains/create'));
const CategoryList = lazy(()=> import('./admin/category/list'));
const CourseDashboard = lazy(()=> import('./admin/course/dashboard'));
const Referrals = lazy(()=> import('./admin/referrals/referral'));
const Comments = lazy(()=> import('./admin/comments/comments'));
const OnboardingDashboard = lazy(()=> import('./admin/onboarding/onboarding'));
const CreateBadge = lazy(()=> import('./admin/badges/create'));
const BadgesList = lazy(()=> import('./admin/badges/badgesList'));
const CreateBanner = lazy(()=> import('./admin/banners/create'))
const BannersList = lazy(()=> import('./admin/banners/list'))
const Onboardings = lazy(()=> import('./admin/onboarding/history'))
const CreateOnboardingLink = lazy(()=> import('./admin/onboarding/create'))
const EditCourse = lazy(()=> import('./admin/course/edit'));
const PageDetails = lazy(()=> import('./admin/course/details'));
const AccessControlSystem = lazy(()=> import('./admin/accesscontrol/acl'));
import ScrollToTop from "react-scroll-to-top";
const Loader = lazy(()=> import('./common/loader'));


const options = {
  autoConfig: false,
  debug: false, // enable logs
};

if(window.env == "production") {
  ReactPixel.init('453586932909373', {}, options);
  ReactPixel.pageView();
  ReactPixel.fbq('track', 'PageView');
}


function App() {
  const location = useLocation();
  if(location.pathname != "/" && window.env == "production") {
    ReactPixel.track('ViewContent', {})
  }
  
  return (
    <> 
    {/* <Topnav /> */}
    <main>
      <ToastContainer />
      <ScrollToTop />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/admin/react/blockchain/create" element={<CreateBlockchain />} />
          <Route exact path="/admin/react/blockchain/:slug/edit" element={<CreateBlockchain />} />
          <Route exact path="/admin/react/blockchain/list" element={<BlockchainList />} />
          <Route exact path="/admin/react/detailed-dashboard" element={<DetailedDashboard />} />
          <Route exact path="/admin/react/dashboard" element={<Dashboard />} />
          <Route exact path="/admin/react/targets" element={<Targets />} />
          <Route exact path="/admin/react/course/:id/targets" element={<Targets />} />
          <Route exact path="/admin/react/course/create" element={<CreateCourse />} />
          <Route exact path="/admin/react/course/list" element={<CourseList />} />
          <Route exact path="/admin/react/category/create" element={<CreateCategory />} />
          <Route exact path="/admin/react/category/:id" element={<EditCategory />} />
          <Route exact path="/admin/react/category/list" element={<CategoryList />} />
          <Route exact path="/admin/react/course/dashboard" element={<CourseDashboard />} />
          <Route exact path="/admin/react/referral" element={<Referrals />} />
          <Route exact path="/admin/react/comments" element={<Comments />} />
          <Route exact path="/admin/react/onboarding" element={<OnboardingDashboard />} />
          <Route exact path="/admin/react/badges/create" element={<CreateBadge />} />
          <Route exact path="/admin/react/badges/list" element={<BadgesList />} />
          <Route exact path="/admin/react/banner/create" element={<CreateBanner />} />
          <Route exact path="/admin/react/banner/:id/edit" element={<CreateBanner />} />
          <Route exact path="/admin/react/banner/list" element={<BannersList />} />
          <Route exact path="/admin/react/onboarding/history" element={<Onboardings />} />
          <Route exact path="/admin/react/onboarding/create" element={<CreateOnboardingLink />} />
          <Route exact path="/admin/react/acl" element={<AccessControlSystem />} />
          <Route exact path="/admin/react/course/:id/dashboard" element={<CourseDashboard />} />
          <Route exact path="/admin/react/course/:id/edit" element={<EditCourse />} />
          <Route exact path="/admin/react/course/:id/details" element={<PageDetails />} />
        </Routes>
      </Suspense>
    </main>
    </>
  );
}

export default App;